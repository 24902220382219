<template>
    <div>
    <hooper class="articles-list" ref="ArticlesSlider" :autoPlay="true" :playSpeed="2000" :transition="400" :infiniteScroll="true" :vertical="true" style="height: 100vh" :itemsToShow="3">
        <hooper-slide v-for="article, index in articles" :key="index">
            <img class="articles-list__image" :src="require(`@/assets/img/articles/svg/${article.id}.svg`)" @click="onSelectArticle($event, article)">
        </hooper-slide>
    </hooper>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Hooper, Slide as HooperSlide } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
    name: "ArticlesList",
    components: {
        Hooper,
        HooperSlide,
    },
    computed: {
        ...mapGetters("ui", ["articles","activeArticle"]),
    },
    methods: {
        onSelectArticle(event, item) {
            this.$emit("selectArticle", {event, item});
        },
    }
}
</script>

<style lang="scss" scoped>
.articles-list {
    &__image {
        height: 85%;
        width: auto;
        margin: 7.5% auto;
        display: block;
        -webkit-user-select: none;
        -webkit-user-drag: none;
        -webkit-app-region: no-drag;
        object-fit: cover;
        object-position: center;
    }
}
</style>